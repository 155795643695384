import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14')
];

export const server_loads = [4];

export const dictionary = {
		"/(main)/(home)": [8,[2]],
		"/(main)/(header)/label": [6,[2,3]],
		"/(main)/(header)/library": [~7,[2,3]],
		"/signin": [14],
		"/(main)/story": [~9,[2]],
		"/(main)/story/[storySlug]": [10,[2,4]],
		"/(main)/story/[storySlug]/page": [~11,[2,4]],
		"/(main)/story/[storySlug]/page/end": [13,[2,4]],
		"/(main)/story/[storySlug]/page/[storyPageNumber]": [12,[2,4,5]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.js';